body,
div,
span,
header,
footer,
nav,
section,
aside,
article,
ul,
dl,
dt,
dd,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
i,
b,
textarea,
button,
input,
select,
figure,
figcaption {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    font-style: normal;
    text-decoration: none;
    font-weight: normal;
    /* font-family: "Microsoft Yahei"; */
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
}
input[type="button"],
input[type="submit"],
input[type="search"],
input[type="reset"] {
    -webkit-appearance: none;
}
textarea {
    -webkit-appearance: none;
}
html {
	/* overflow: hidden; */
}
body {
	/* overflow: scroll; */
}
html,
body {
    height: 100%;
    width: 100%;
    background-color: #F7F7FA;
}
.clear:after {
    content: "";
    display: block;
    clear: both;
}
.clear {
    zoom: 1;
}
.back_img {
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.margin {
    margin: 0 auto;
}
.left {
    float: left;
}
.right {
    float: right;
}
.hide {
    display: none;
}
.show {
    display: block;
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@keyframes backOpacity {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
.animation_opactiy {
    animation: backOpacity 2s ease-in-out infinite;
}
.app {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #1e1d1d;
    max-width: 750px;
    margin: 0 auto;
}
#app {
    font-family: "PingFang SC", "Helvetica Neue", Helvetica, "Hiragino Sans GB",
        "Microsoft YaHei", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #1a1818;
    max-width: 750px;
    margin: 0 auto;
    height: 100%;
}
.fr {
    float: right;
}
.fl {
    float: left;
}
.ab {
    position: absolute;
}
.re {
    position: relative;
}
.inlineBlock {
    display: inline-block;
}

/*--禁止选中--*/
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently*/
}

/* loading */
#loadModel {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
    display: none;
}
#loadModel div {
    width: 2rem;
    height: 2rem;
    border-radius: 0.3rem;
    background: rgba(0, 0, 0, 0.6);
    font-size: 0.3rem;
    color: #fff;
    text-align: center;
    line-height: 2rem;
    margin: 4rem auto;
}

.wrap {
    background: #f8f5f5;
    padding-bottom: 1.8rem;
    min-height: 100vh;
    box-sizing: border-box;
}
/*-------发收红包共有样式-------*/
.whiteBoxWrap {
    padding: 1.04rem 0.4rem 0;
    width: 100%;
    box-sizing: border-box;
}
.whiteBox {
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 0.2rem;
    margin: 0 auto;
    position: relative;
}
.photoBox {
    width: 1.34rem;
    height: 1.34rem;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: -0.67rem;
    left: calc(50% - 0.67rem);
    background: #fff;
}
.photo {
    background: #e5e5e5;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50%;
    overflow: hidden;
    margin: 0.12rem auto;
}
.photoImg {
    width: 1.1rem;
    height: 1.1rem;
}
.not-data {
    margin-top: 4rem;
}
.not-data img {
    width: 50%;
    margin: 0 auto;
    display: block;
}
.not-data p {
    color: #02172E;
    text-align: center;
}
body {
	font-family: 'PingFangSC-Regular', 'haose','PingFang SC', "微软雅黑", "Microsoft Yahei";
}
* {
	word-break: break-all;
}

/* 内容上面-通用顶部安全距离 */
.g_safe_top{
    padding-top: 0.88rem;
    padding-top: calc(env(safe-area-inset-top) + 0.88rem);
}