
@import "~assets/css/iconfont.css";


#app {
   // 所有文件 可用
   // --bgColor: linear-gradient(to right, rgb(69, 143, 248), #1464E1);
   // --themeColor: #2D82FA;
   --bdColor: #F7F7FA;
   // --qianlan: #C8DCFF;

   // @bgColor: #1464E1; // 只能 本文件

   font-size: .3rem;
   width: 100%;
   height: 100%;
   position: relative;
}
@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;